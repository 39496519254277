<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="flex items-center my-2 text-sm font-bold text-gray-500">
        <span>{{
          $t(
            'components.vehicleOperationPrimer.addEdit.steps.subpart.title.manageSubpart'
          )
        }}</span>
        <div
          class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
          @click="onAddSubpart"
        >
          <i class="fas fa-plus-circle" style="color: black"></i>
        </div>
      </div>
      <div v-if="form.length > 0">
        <div
          class="flex items-center pb-2 "
          v-for="(item, index) in form"
          :key="index"
        >
          <div class="flex gap-2 items-center w-11/12 ">
            <AppInput
              v-model="item.name"
              type="text"
              rules="required"
              placeholder="e.g. Motor Wiring"
              :showError="false"
            />
          </div>

          <div class="flex justify-center w-1/12 item-center ">
            <div
              class="col-span-1 cursor-pointer focus:text-gray-400"
              @click="onRemoveSubpart(index)"
            >
              <i class="fas fa-minus-circle" style="color: #d90a20"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-gray-500  border-dashed border border-gray-300 px-2 py-5 text-sm rounded-lg flex items-center justify-center"
      >
        <p>
          {{
            $t(
              'components.vehicleOperationPrimer.addEdit.steps.subpart.placeholder.noData'
            )
          }}
        </p>
      </div>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'

export default {
  name: 'Step2',

  components: {},

  props: {
    primaryKey: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,

      form: [],
      toDeleteForm: [],
    }
  },

  computed: {},

  watch: {
    formData: {
      immediate: true,
      deep: false,
      handler(data) {
        if (data) {
          console.log('getStep2FormData', data)
          this.form = [...data]
        }
      },
    },

    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
  },

  methods: {
    onAddSubpart() {
      this.form.unshift({ name: '' })
    },
    onRemoveSubpart(index) {
      if (this.isEditing) {
        let toRemove = this.form[index]
        if (
          this.form.filter((entry) => entry.name === toRemove.name).length > 0
        )
          this.toDeleteForm.push(this.form[index])
        console.log('onRemoveSubpart', this.toDeleteForm)
      }
      this.form.splice(index, 1)
    },
    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      let subPartRequests = []
      if (this.form.length) {
        this.form.forEach((element) => {
          console.log(`Elem ${element.type}`, element)
          const isUpdateReq = 'id' in element
          const method = isUpdateReq ? 'PATCH' : 'POST'
          const url = isUpdateReq
            ? useEndpoints.operationPrimer.vehicleInspectionSubPart.update(
                element.id
              )
            : useEndpoints.operationPrimer.vehicleInspectionSubPart.create()

          const data = new FormData()
          data.append('name', element.name)
          data.append('part', this.primaryKey)

          const subpartReq = this.$http({
            url: url,
            method: method,
            data: data,
          })
          subPartRequests.push(subpartReq)
        })
      }
      if (this.isEditing && this.toDeleteForm.length) {
        this.toDeleteForm.forEach((element) => {
          if ('id' in element) {
            const subpartReq = this.$http({
              url: useEndpoints.operationPrimer.vehicleInspectionSubPart.delete(
                element.id
              ),
              method: 'DELETE',
            })
            subPartRequests.push(subpartReq)
          }
        })
      }

      const requests = [...subPartRequests]

      // submit
      this.isLoading = true
      await this.$http
        .all(requests)
        .then(
          this.$http.spread(() => {
            this.$http
              .get(
                useEndpoints.operationPrimer.vehicleInspectionPart.details(
                  this.primaryKey
                )
              )
              .then((res) => {
                this.toDeleteForm = []
                this.form = res.data.sub_parts
                const data = res.data.sub_parts

                this.$emit('save', { step: 2, data })

                const message = `Subpart ${
                  this.isEditing ? 'updated' : 'added'
                } successfully`

                this.$notify({
                  group: 'bottomLeft',
                  type: 'success',
                  title: 'Success',
                  text: message,
                })
              })
              .finally(() => (this.isLoading = false))
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occurred!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occurred!',
            text: err.response.data.message,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped></style>
